<template>
  <div class="container-fluid mt-3">
    <Breadcrumb current_page="سلة المحذوفات"/>

    <el-card class="mt-3 overflow-auto">

      <el-table id="top-table" :data="news_list" class="mb-4" stripe style="width: 100%"
                max-height="850" v-loading="loading">
        <el-table-column prop="id" class-name="px-3" label="الترتيب" width="100"/>
        <el-table-column prop="title" width="480" class-name="text-end" label="عنوان الخبر">
          <template #default="scope">
            <a :href="scope.row.url" :title="scope.row.title" class="news-link" target="_blank">
              <span v-if="scope.row.is_draft" class="text-danger ms-2">[مسودة]</span>
              {{ scope.row.title }}
            </a>
          </template>
        </el-table-column>
        <el-table-column prop="no_of_views" width="100" label="المشاهدات"/>

        <el-table-column label="الناشر" width="130">
          <template #default="scope">
            {{ scope.row.publisher.display_name }}
          </template>
        </el-table-column>

        <el-table-column prop="category" width="200" class-name="px-3" label="التصنيف">
          <template #default="scope">
            {{ scope.row.category.name }}
          </template>
        </el-table-column>

        <el-table-column class-name="px-3" width="180" label="التاريخ">
          <template #default="scope">
            <div dir="ltr" class="text-end">
              {{
                dateFormatter(scope.row.date_to_publish) ? dateFormatter(scope.row.date_to_publish) : dateFormatter(scope.row.created_at)
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="الأدوات" width="120" class-name="control-btns">
          <template #default="scope">
            <el-popconfirm
                hide-icon
                confirmButtonType="danger"
                cancelButtonType="default"
                confirmButtonText="نعم"
                cancelButtonText="لا"
                iconColor="red"
                @confirm="confirmRestore(scope.row.id)"
                title="هل أنت متأكد أنك تريد استعادة هذا الخبر؟">
              <template #reference>
                <el-button title="استعادة الخبر" class="py-1 px-2 btn btn-restore">
                  <i class="las la-redo-alt"></i>
                </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>

      </el-table>

      <pagination v-if="news_list.length" v-model="page" :records="total" :per-page="per_page" :options="paginationOptions" @paginate="fetchAllTrashed"/>


    </el-card>



  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {getDeletedNews, restoreSingleNews} from "@/services/news";
import Loader from "@/components/Loader";
import {useToast} from "vue-toastification";
import {dateFormatter} from "../../utils/formatDate";

export default {
  name: "Deleted News",
  components: {Loader, Breadcrumb},
  data(){
    return {
      toast: useToast(),
      loading: true,
      news_list: [],
      page: 1,
      total: 0,
      per_page:25,
      paginationOptions: {
        chunk: 5,
        theme: "bootstrap4",
        hideCount: true
      }

    }
  },
  mounted() {
    this.fetchAllTrashed();
  },
  computed:{
    dateFormatter: () => date => dateFormatter(date)
  },
  methods: {
    fetchAllTrashed(){
      this.loading = true
      getDeletedNews()
      .then(result=>{
        this.loading = false
        this.total = result.data.total;
        this.per_page = result.data.per_page;
        this.news_list = result.data.data
      })
    },
    confirmRestore(id) {
      restoreSingleNews(id)
      .then(()=> {
        let newsToRestore = this.news_list.findIndex(news => news.id === id)
        this.news_list.splice(newsToRestore,1);
        this.toast.success("تم استعادة الخبر بنجاح", {
          position: "top-center",
        });
      })
      .catch(() => {
        this.toast.error("حدث خطأ, الرجاء المحاولة مرة أخرى", {
          position: "top-center",
        });
      })
    }
  }
}
</script>

<style lang="scss">
.btn-restore {
  background-color: transparent;
  color: #28c76f;
  border-color: #28c76f;

  &:hover {
    color: #fff;
    background-color: #28c76f;
  }
}


.el-popconfirm__action {
  display: flex;
  gap: .5rem;
  flex-direction: row-reverse;
  justify-content: center;

  .el-button + .el-button {
    margin-left: 0;
  }

  button {
    min-width: 50px;
  }
}

.custom-width {
  min-width: 300px;
}
</style>
